import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Config } from './Config';
import { ApiRequestService } from '../api-client/api-requests.service';
import apiConfig from '../api-client/api-config';
import { UPDATE_VERIFICATION } from './state-management/actions';
import { IAppState } from './state-management/store';
import { Constants } from './Constants';
import { ResponseHandlerService } from './response-handler.service';

export interface VerificationCallback {
  onSuccessSendCode(result: any): void;
  onFailure(result: any): void;
  onSuccess(result: any): void;
}

@Injectable()
export class VerificationService {
  constructor(
    private apiRequest: ApiRequestService,
    private ngRedux: NgRedux<IAppState>,
    private responseHandler: ResponseHandlerService
  ) {}

  sendCode(
    medium: string,
    address: string,
    type: string,
    callback?: VerificationCallback
  ) {
    //HALOC-3023: Added Language header in API
    const body = {
      codetype: type,
      medium: medium,
      address: address ? address.toLowerCase().replace(/ /g, '') : address,
      brandname: this.ngRedux.getState().userBrand,
      preferredlanguage:
        Config.LANGUAGES[
          this.ngRedux.getState().preferredLanguage.toUpperCase()
        ],
    };

    /* this.apiClient.makeCall(null, 'post', apiConfig.MfaCode, body, (err, res) => {
      if (res) {
        if (res.success) {
          let verifyObject = this.ngRedux.getState().verify;
          if (!verifyObject || verifyObject == null) {
            verifyObject = {};
          }
          verifyObject[medium] = {};
          verifyObject[medium].address = address;
          verifyObject[medium].verify = false;
          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject
          });
          callback.onSuccessSendCode(res);
        } else {
          callback.onFailure(res);
        }
      } else {
        callback.onFailure(res);
      }
    }); */

    this.apiRequest
    .callApi(Constants.API_METHODS.POST, apiConfig.MfaCode.uri, body)
    .subscribe({
      next: (res) => {
        let verifyObject = this.ngRedux.getState().verify;
          if (!verifyObject || verifyObject == null) {
            verifyObject = {};
          }
          verifyObject[medium] = {};
          verifyObject[medium].address = address;
          verifyObject[medium].verify = false;
          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject
          });
          callback.onSuccessSendCode(res);
      },
      error: (err) => {
        let data = this.responseHandler.parseErrorResponseV2(err);
        callback.onFailure({ data });
      },
    });
  }


  
  sendCodeSilent(medium: string, address: string, type: string, callback?: VerificationCallback) {

    const body = {
      codetype: type,
      medium: medium,
      address: address ? address.toLowerCase().replace(/ /g, '') : address,
      brandname: this.ngRedux.getState().userBrand,
      preferredlanguage:
        Config.LANGUAGES[
          this.ngRedux.getState().preferredLanguage.toUpperCase()
        ],
    };

    this.apiRequest.callApi(Constants.API_METHODS.POST, apiConfig.MfaCode.uri, body, false).subscribe({
      next: (res) => {
        if(res){
          let verifyObject = this.ngRedux.getState().verify;
          if (!verifyObject || verifyObject == null) {
            verifyObject = {};
          }
          verifyObject[medium] = {};
          verifyObject[medium].address = address;
          verifyObject[medium].verify = false;
          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject
          });
          callback.onSuccessSendCode(res);
        }
      },
      error: (err) => {
        let data = this.responseHandler.parseErrorResponseV2(err);
        callback.onFailure(data);
      }
    })

    /* this.apiClient.makeSilentCall(null, 'post', apiConfig.MfaCode, body, (err, res) => {
      if (res) {
        if (res.success) {
          let verifyObject = this.ngRedux.getState().verify;
          if (!verifyObject || verifyObject == null) {
            verifyObject = {};
          }
          verifyObject[medium] = {};
          verifyObject[medium].address = address;
          verifyObject[medium].verify = false;
          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject
          });
          callback.onSuccessSendCode(res);
        } else {
          callback.onFailure(res);
        }
      } else {
        callback.onFailure(res);
      }
    }); */
  }

  sendCodeWithCaptcha(
    medium: string,
    address: string,
    type: string,
    captchaRespopnce: string,
    callback?: VerificationCallback
  ) {
        let body = {
      codetype: type,
      medium: medium,
      address: address ? address.toLowerCase().replace(/ /g, '') : address,
      captchatoken: captchaRespopnce,
      brandname: this.ngRedux.getState().userBrand,
      provider:localStorage.getItem('PROVIDER'),
      email:sessionStorage.getItem('GoogleEmail'),
      preferredlanguage:
        Config.LANGUAGES[
          this.ngRedux.getState().preferredLanguage.toUpperCase()
        ],
    };

    let configObject = JSON.parse(localStorage.getItem('config'))
    let disableCaptcha = configObject && configObject.Feature.DisableCaptcha
    // pass flag to manage captcha field on backend and bypass captcha verification
    if (disableCaptcha) 
    body['bypasscaptcha'] = disableCaptcha

    this.apiRequest
      .callApi(Constants.API_METHODS.POST, apiConfig.MfaCode.uri, body)
      .subscribe({
        next: (res) => {
          let verifyObject = this.ngRedux.getState().verify;
          if (!verifyObject || verifyObject == null) {
            verifyObject = {};
          }
          verifyObject[medium] = {};
          verifyObject[medium].address = address;
          verifyObject[medium].verify = false;
          // verify state gets lost on refresh, so setting it in localstorage once it gets set
          localStorage.setItem('verify', JSON.stringify(verifyObject));
          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject,
          });
          callback.onSuccessSendCode({ ...res, medium });
        },
        error: (err) => {
          let data = this.responseHandler.parseErrorResponseV2(err);
          callback.onFailure({ data, medium });
        },
      });

  }

  checkCode(
    medium: string,
    code: string,
    type: string,
    callback?: VerificationCallback,
    isGlobalLoader = false
  ) {
    const verifyObject: any = this.ngRedux.getState().verify;
    const body = {
      address: verifyObject[medium].address
        ? verifyObject[medium].address.toLowerCase()
        : verifyObject[medium].address,
      medium: medium,
      codetype: type,
      code: code,
      preferredlanguage:
        Config.LANGUAGES[
          this.ngRedux.getState().preferredLanguage.toUpperCase()
        ],
    };

    this.apiRequest
      .callApi(Constants.API_METHODS.PATCH, apiConfig.MfaCode.uri, body, false)
      .subscribe({
        next: (res) => {
          verifyObject[medium].verify = true;
          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject,
          });
          callback.onSuccess(res);
          return;
        },
        error: (err) => {
          console.log(err);
          let data = this.responseHandler.parseErrorResponseV2(err);
          callback.onFailure({ data });
          return;
        },
      });

    /* this.apiClient.makeCall(null, 'patch', apiConfig.MfaCode, body, (err, res) => {
    if (res) {
      if (res.success) {
        verifyObject[medium].verify = true;
        this.ngRedux.dispatch({
          type: UPDATE_VERIFICATION,
          object: verifyObject
        });
        callback.onSuccess(res);
        return;
      }
    }
    callback.onFailure(res);
    return;
  }, isGlobalLoader); */
  }

  checkVerificationCode(
    address: string,
    medium: string,
    code: string,
    type: string,
    callback?: VerificationCallback
  ) {
    const verifyObject = this.ngRedux.getState().verify;
    const body = {
      address: address,
      medium: medium,
      codetype: type,
      code: code,
      preferredlanguage:
        Config.LANGUAGES[
          this.ngRedux.getState().preferredLanguage.toUpperCase()
        ],
    };

    this.apiRequest.callApi(Constants.API_METHODS.PATCH, apiConfig.MfaCode.uri, body, false) .subscribe({
      next: (res) => {
        verifyObject[medium].verify = true;
        verifyObject[medium].address = address;
        this.ngRedux.dispatch({
          type: UPDATE_VERIFICATION,
          object: verifyObject
        });
        callback.onSuccess(res);
        return;
      },
      error: (err) => {
        console.log(err);
        let data = this.responseHandler.parseErrorResponseV2(err);
        callback.onFailure({ data });
        return;
      },
    })

    /*  this.apiClient.makeSilentCall(null, 'patch', apiConfig.MfaCode, body, (err, res) => {
     if (res) {
       if (res.success) {
         verifyObject[medium].verify = true;
         verifyObject[medium].address = address;
         this.ngRedux.dispatch({
           type: UPDATE_VERIFICATION,
           object: verifyObject
         });
         callback.onSuccess(res);
         return;
       }
     }
     callback.onFailure(res);
     return;
   }); */
  }
}
